import * as React from "react";
import { btn } from "../../styles/components/buttons.module.scss";

const BuyExclusiveIslandOvernightBtn = () => {
	return (
		<a
			href="https://fareharbor.com/embeds/book/tampabayboatingadventures/items/453556/calendar/2023/11/?full-items=yes&flow=792230"
			className={btn}
			rel="noreferrer"
		>
			Book Package
		</a>
	);
};

export default BuyExclusiveIslandOvernightBtn;
