import * as React from "react";
import { useState } from "react";
import LayoutService from "../../templates/LayoutService";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import BuyExclusiveIslandOvernightBtn from "../../components/buttons/BuyExclusiveIslandOvernightBtn";
import { Link } from "gatsby";
import "../../styles/components/titles.module.scss";
import "../../styles/components/header.module.scss";
import "../../styles/components/content.module.scss";
import "../../styles/components/contenttoggles.scss";
import { btn } from '../../styles/components/buttons.module.scss'
import { imgBox } from "../../styles/components/boxes.module.scss";
import {
  bannerBlock,
  bannerBlockHeadline,
  bannerBlockTagline,
  bannerBlockTitleSm,
  pageContentBlock,
  pageServiceDivider,
  serviceInfoBlock,
  serviceInfoBtns,
  pageCalloutBlock,
  pageContentMedia,
} from "../../styles/components/blocks.module.scss";
import { hideBlockSM } from "../../styles/components/helpers.module.scss";
import OvernightPartyInfo from "../../components/blocks/OvernightPartyInfo";
import GalleryFourHour from "../../components/galleries/GalleryFourHour";

const ExclusiveIslandOvernightPage = () => {
  const [isActive, setActive] = useState(true);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <LayoutService>
      <Helmet>
        <title>
          Exclusive Island Overnight Party | Tampa Bay Boating Adventures
        </title>
        <meta
          name="description"
          content="Party all night on our EXCLUSIVE PRIVATE ISLAND in Tampa Bay. Party with friends, enjoy fire pits, raised tent platforms, and explore trails. We handle the rough aspects. No noise restrictions - just fun and memories! Contact us to book your once-in-a-lifetime experience now."
        />
        <meta name="robots" content="Camping, Island, Overnight" />
      </Helmet>
      <header className="container">
        <div className="row">
          <div className="col-xxs-12">
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="bridge near tampa bay"
                  src="../../images/banners/bridge-near-tampa-bay.jpg"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>
                  EXCLUSIVE ISLAND OVERNIGHT PARTY BOAT CHARTER IN TAMPA BAY
                </h1>
                <span className={bannerBlockTitleSm}>
                  CAMP ON A PRIVATE ISLAND
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div className="row">
          <div className="col-xxs-12">
            <GalleryFourHour />
            <div className={serviceInfoBlock}>
              <h2>overnight Party Boat Package Summary</h2>
              <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",
                
                  justifyContent: "flex-start",
                }}
              >
                <BuyExclusiveIslandOvernightBtn />
                <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>

                {/* <a href="#description" className={btn} onClick={toggleClass}>{isActive ? "Hide Description" : "View Description"}</a> */}
              </div>

              <OvernightPartyInfo />
            </div>
            <div
              id="pageContentContainer"
              className={isActive ? "pageContentOpen" : "noClass"}
            >
              <div className="anchor" id="description"></div>
              <div className={pageContentBlock}>
                <hr className={pageServiceDivider} />
                <h2>
                  Our Exclusive Island Overnight Party Charter Is a
                  Once-in-a-Lifetime Experience
                </h2>
                <p>
                  Set up camp on our party island to enjoy your own little slice
                  of paradise.
                </p>
                <p>
                  Looking for an unforgettable party experience in the Tampa Bay
                  area? If so, you’ll want to schedule our exclusive island
                  overnight party excursion. This is a one-of-a-kind adventure
                  that promises to be the experience of a lifetime! At Tampa Bay
                  Boating Adventures, we have exclusive rights to a private
                  party island on the Alafia River. With our overnight party
                  excursion, you and up to 5 of your friends can have the entire
                  island to yourselves! That’s right, no one else will be there,
                  so you can be as loud as you want and party all night long
                  without having to worry about disturbing anyone! This island
                  features two fire pits, raised platforms for tents, and trails
                  that you can explore! You’re welcome to bring any drinks you
                  want—including alcohol—and if you also want to bring some
                  food, we’ll be happy to cook it up for you!
                </p>
                <hr />
                <h2>
                  This exclusive party island features two fire pits, raised
                  tent platforms, trails, and more!
                </h2>
                <p>
                  Our exclusive party island is perfect for those who want to
                  turn a party night into an all-out adventure. The island’s
                  natural beauty and prime location alone are enough to make it
                  the perfect spot for an overnight camping trip. But there are
                  some added amenities that will make your experience even
                  better, including:
                </p>
                <h3>Fire Pits</h3>
                <p>
                  There are two large fire pits on this island that you can use.
                  Gather around the fire to stay warm and cozy with your
                  friends, or build a giant bonfire to provide the perfect
                  backdrop for your unforgettable night of partying!
                </p>
                <h3>Raised Tent Platforms</h3>
                <p>
                  Our raised tent platforms are the perfect place for you to set
                  camp. With these platforms, you’ll be able to get off the
                  ground and away from some of the insects when it’s time for
                  sleep …or other late-night activities.
                </p>
                <p>
                  <h3>Trails</h3>
                  <p>
                    There are numerous trails throughout this 2-acre island, so
                    you and your friends will have ample opportunity to explore
                    its natural beauty.
                  </p>
                  <h3>Outhouses</h3>
                  <p>
                    When nature calls, you’ll be happy to know there are some
                    outhouses that you can use!
                  </p>
                  <div className={pageCalloutBlock}>
                    <p>
                      This island is great to fish off of, so bring some poles
                      if you’re looking to do some fishing!
                    </p>
                  </div>
                  <BuyExclusiveIslandOvernightBtn />
                  <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>
                </p>
                <hr />
                <h2>
                  There is no noise ordinance on our exclusive party island!
                </h2>
                <p>
                  At Tampa Bay Boating Adventures, we believe that the best
                  parties are the ones where you can let loose and have fun
                  without worrying about noise restrictions. And since there’s
                  no noise ordinance on our exclusive party island, you can
                  party as loud as you want! You and your friends can blast your
                  music, dance the night away, play drinking games, or do
                  anything else you want without having to worry about
                  disturbing anyone or being told to “keep it down.”
                </p>
                <hr />
                <h2>
                  We’ll bring some basic supplies and cook for you, and you can
                  bring anything else you want!
                </h2>
                <p>
                  When it comes to the basics, we’ll have you and your friends
                  covered with some water and snacks. However, you can also
                  bring whatever you’d like. Grab a cooler and fill it up with
                  any food or drinks you want, including alcohol! We’ll even be
                  happy to cook for you and your party. We’ll bring a grill and{" "}
                  <strong>
                    we can throw on some burgers, hot dogs, veggie burgers, or
                    anything else you want
                  </strong>
                  !
                </p>
                <hr />
                <h2>
                  Ready for the experience of a lifetime? Give us a call today
                  to schedule our exclusive island overnight party charter!
                </h2>
                <p>
                  If you’re ready to have the experience of a lifetime and make
                  unforgettable memories with your friends, you’ll want to
                  schedule our exclusive island overnight party charter. Make
                  sure to book your trip soon because our calendar tends to fill
                  up quickly! To reserve your trip today, fill out our
                  submission form or send us an email and we’ll get back to you
                  as soon as we can! We look forward to hearing from you!
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </LayoutService>
  );
};

export default ExclusiveIslandOvernightPage;
