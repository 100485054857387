import * as React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import { galleryContainer } from '../../styles/components/galleries.module.scss'
import '../../styles/components/swiper.css'
import { imgBox } from '../../styles/components/boxes.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

const GalleryFourHour = () => {
  return (
    <div className={galleryContainer}>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Beautiful girl on party boat with guys holding drinks in Tampa, FL."
              src="../../images/galleries/four-hour-charter/beautiful-girl-on-boat-with-guys-holding-drinks.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Girls holding party boat flags in Tampa Bay, Florida."
              src="../../images/galleries/four-hour-charter/girls-holding-party-boat-flags.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Girls partying with drinks in Tampa, Florida."
              src="../../images/galleries/four-hour-charter/girls-partying-with-drinks.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Hot girls on a party boat near Beer Can Island in Tampa Bay, FL."
              src="../../images/galleries/four-hour-charter/hot-girls-on-party-boat.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Party boat girls holding a flag in Tampa, Florida."
              src="../../images/galleries/four-hour-charter/party-girls-holding-flag.jpg"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default GalleryFourHour